const slug = require('slug')

export type ThemeType = {
  id: string
  data: {
    Name: string
    Order: number
    PictoTheme: {
      localFiles: [{
        childImageSharp: {
          fluid: any
        }
        ext: string
        publicURL: string
      }]
    }
  }
}

export type DomainType = {
  id: string
  data: {
    Name: string
    Order: number
  }
}

export type allThemeType = { nodes: ThemeType[] }

export type ActionType = {
  id: string,
  data: {
    Name: string
    ID: string
    Stake: {
      childMarkdownRemark: { html: string }
    }
    Details: {
      childMarkdownRemark: { html: string }
    }
    MoreInfo: {
      childMarkdownRemark: { html: string }
    }
    Category: string
    Type: string
    Domains: [DomainType]
    Target: string[]
    Theme: ThemeType
  }
}

export type allActionType = { nodes: ActionType[] }

export class Domain {
  id: string
  name: string
  order: number

  constructor(raw: DomainType) {
    this.id = raw.id
    this.name = raw.data.Name
    this.order = raw.data.Order
  }
}

export class Theme {
  id: string
  name: string
  pictoURL: string
  order: number

  constructor(raw: ThemeType) {
    this.id = raw.id
    this.name = raw.data.Name
    this.pictoURL = raw.data.PictoTheme?.localFiles[0]?.publicURL
    this.order = raw.data.Order
  }
}

export class Action {
  id: string
  name: string
  externalID: string
  stake: string
  details: string
  moreInfo: string
  category: string
  type: string
  domains: Domain[]
  targets: string[]
  theme: Theme

  constructor(raw: ActionType) {
    this.id = raw.id
    this.name = raw.data.Name
    this.externalID = "" + raw.data.ID
    this.stake = raw.data.Stake?.childMarkdownRemark.html
    this.details = raw.data.Details?.childMarkdownRemark.html
    this.moreInfo = raw.data.MoreInfo?.childMarkdownRemark.html
    this.category = raw.data.Category
    this.type = raw.data.Type
    this.domains = raw.data.Domains?.map((d) => new Domain(d))
    this.targets = raw.data.Target
    if (raw.data.Theme && raw.data.Theme[0]) {
      this.theme = new Theme(raw.data.Theme[0])
    }
  }

  get slug(): string {
    return `/action/${slug(this.name)}`
  }
}