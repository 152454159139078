import React, { createContext, useContext, useEffect, useMemo, useState, Dispatch, SetStateAction } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Action } from '../utils/model'
import { useLocation } from "@reach/router";

export enum FilterType {
  query = "query",
  theme = "theme",
  domain = "domain",
  none = "none"
}

type SearchFilters = {
  actions: string[],
  setActions: Dispatch<SetStateAction<string[]>>,
  filtertype: FilterType,
  setFiltertype: Dispatch<SetStateAction<FilterType>>,
  filtervalue: string,
  setFiltervalue: Dispatch<SetStateAction<string>>,
  all: Action[]
}

type SearchContextActions = {
  selectAction: (ID: string) => void,
  unselectAction: (ID: string) => void,
  setFilter: (type: FilterType, value: string) => void
}

type SearchContextData = {
  filters: SearchFilters,
  actions: SearchContextActions,
  form: string
}

export const SearchContext = createContext<SearchContextData | null>(null);

type SearchContextProviderProps = {
  children: React.ReactNode
}

export function useSearchContextActions(searchFilters: SearchFilters) {

  return useMemo<SearchContextActions>(() => ({
    selectAction: (ID: string) => {
      if (ID != "" && ID != undefined) {
        const actions = [...searchFilters.actions, ID];
        searchFilters.setActions(actions)
        typeof window !== 'undefined' && localStorage.setItem("action", actions.join(','));
      }
    },
    unselectAction: (ID: string) => {
      const actions = searchFilters.actions.filter(a => a !== ID);
      searchFilters.setActions(actions)
      typeof window !== 'undefined' && localStorage.setItem("action", actions.join(','));
    },
    setFilter: (type: FilterType, value: string) => {
      searchFilters.setFiltertype(type)
      searchFilters.setFiltervalue(value)

      if (typeof window !== 'undefined') {
        localStorage.setItem("filtertype", FilterType[type]);
        localStorage.setItem("filtervalue", value);
      }
    }
  }), [searchFilters]);
}

export const filterAction = (all: Action[], filtertype: FilterType, filtervalue: string) => {
  return all
    .filter(a => {

      switch (filtertype) {
        case FilterType.domain: {
          return (a.domains?.find(d => d.id == filtervalue) != undefined)
        }
        case FilterType.theme: {
          return (a.theme.id == filtervalue)
        }
        case FilterType.query: {
          return (a.name.toLowerCase().indexOf(filtervalue?.toLowerCase()) > -1)
        }
        default: {
          return true
        }
      }

    })
    .sort((a, b) => parseInt(a.externalID) - parseInt(b.externalID));
}

export const SearchContextProvider = (props: SearchContextProviderProps) => {

  const data = useStaticQuery(graphql`
    query AllActionsQuery2 {
      actions: allAirtable(
        filter: { table: { eq: "Action" }
      }) {
        nodes {
          id
          data {
            Name
            ID
            Stake {
              childMarkdownRemark {
                html
              }
            }
            Domains {
              id
              data {
                Name
              }
            }
            Theme {
              id
              data {
                  Name
                  PictoTheme {
                      localFiles {
                          publicURL
                      }
                  }
              }
            }
          }
        }
      }
    }
  `)


  const all = data.actions.nodes.map(raw => new Action(raw))

  // STORE STATE IN LOCALSTORAGE
  const [actions, setActions] = useState<string[]>(
    typeof window !== 'undefined' && localStorage.getItem('action')?.split(',').filter(a => a != '') || []
  );

  const [filtertype, setFiltertype] = useState<FilterType>(
    (typeof window !== 'undefined' && FilterType[localStorage.getItem('filtertype')]) || undefined
  );

  const [filtervalue, setFiltervalue] = useState<string>(
    typeof window !== 'undefined' && localStorage.getItem('filtervalue') || undefined
  );

  const searchFilters: SearchFilters = {
    actions: actions,
    setActions: setActions,
    filtertype: filtertype,
    setFiltertype: setFiltertype,
    filtervalue: filtervalue,
    setFiltervalue: setFiltervalue,
    all: all
  }

  const searchContextActions = useSearchContextActions(searchFilters);

  const [formUrl, setFormUrl] = useState<string>(
    typeof window !== 'undefined' && localStorage.getItem('formUrl') || undefined
  );

  const location = useLocation();

  useEffect(() => {
    if(typeof window !== 'undefined') {
      setFormUrl(localStorage.getItem('formUrl') || undefined)
    }
  }, [location]);

  const searchContextData = useMemo<SearchContextData>(() => ({
    actions: searchContextActions,
    filters: searchFilters,
    form: formUrl
  }), [searchContextActions, searchFilters]);

  return (
    <SearchContext.Provider value={searchContextData}>
      {props.children}
    </SearchContext.Provider>
  );
};

export function useSearchContext(): SearchContextData {
  const searchContextData = useContext(SearchContext)
  if (searchContextData === null) {
    throw new Error("SearchContextProvider is missing in parent dom.")
  }
  return searchContextData
}
